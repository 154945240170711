<template>
  <div class="card" style="min-height: 200px">
    <vue3-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        pdf-content-width="100%"
        :htmlToPdfOptions="htmlToPdfOptions"

        ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1">
            <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
          </div>

          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Invoice
            </p>
            <hr>
          </div>

          <div class="row p-2">
            <div class="col-12 col-lg-6">
              <p class="h4">Customer Details</p>
              <p v-if="invoice.contact">{{ invoice.contact.name }}</p>
            </div>
            <div class="col-12 col-lg-6">
              <p><strong>Invoice No: </strong> {{ invoice.bill_number }}</p>
              <p><strong>Invoice Date: </strong> {{ invoice.date }}</p>
            </div>

            <div class="col-12 table-responsive py-2">
              <invoice-table :has-item-details="Boolean(invoice.has_item_detail)"
                             :table-items="tableItems"></invoice-table>
            </div>

            <div class="col-12 col-lg-8">
              <p>
                <strong>In Words: </strong> {{ (invoice.total_amount) ? currencyToWord(invoice.total_amount) : '' }}
              </p>
              {{ invoice.description }}
            </div>

            <div class="col-12 col-lg-4">
              <table class="table table-bordered">
                <tbody>
                <tr>
                  <th>Sub Total</th>
                  <td class="text-right">{{ formatCurrency(invoice.subtotal_amount) }}</td>
                </tr>
                <tr>
                  <th>VAT Amount</th>
                  <td class="text-right">{{ formatCurrency(invoice.vat_amount) }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td class="text-right">{{ formatCurrency(invoice.total_amount) }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <hr class="mt-2">

            <div class="row" v-if="company.name" id="footerContent">
              <div class="col-12 text-center">
                <p>
                  {{ company.name }}
                  Address: {{ company.address }},
                  Phone: {{ company.phone }}
                  E-mail: {{ company.email }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>

<script>
import handleCompany from "@/services/modules/company";
import handlePurchase from "@/services/modules/purchase";
import Loader from "@/components/atom/LoaderComponent";
import InvoiceTable from "@/components/molecule/company/inventory/sales/InvoiceTable";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import handlePurchaseAndSalesReturn from "@/services/modules/purchaseSalesReturn";

export default {
  name: "Print",
  components: {
    Loader,
    InvoiceTable,
    Vue3Html2pdf
  },

  setup() {
    const company = ref({});
    const loader = ref(false);
    const invoice = ref({});
    const html2Pdf = ref(null);
    const {fetchCompanyInfo} = handleCompany();
    const {formatCurrency, currencyToWord} = handlePurchase();
    const {fetchPurchaseReturnBill} = handlePurchaseAndSalesReturn();
    const route = useRoute();
    const showError = inject('showError');

    const bill_number = computed(() => {
      return invoice.value.bill_number;
    });

    const htmlToPdfOptions = ref({
      margin: 0,
      filename: bill_number,
      image: {
        type: 'jpeg',
        quality: 1
      },

      enableLinks: false,

      html2canvas: {
        scale: 2,
        useCORS: true
      },

      jsPDF: {
        unit: 'mm',
        format: 'a3',
        orientation: 'portrait'
      }
    });

    const tableItems = computed(() => {
      return invoice.value.has_item_detail ? invoice.value.general : invoice.value.ledgers;
    })

    const exportToPDF = () => {
      html2Pdf.value.generatePdf();
    }

    onMounted(() => {
      loader.value = true;
      const companyQuery = `?company_id=${route.params.companyId}`;
      const fetchCompany = fetchCompanyInfo(route.params.companyId);
      const fetchInvoice = fetchPurchaseReturnBill(route.params.purchaseReturnId, companyQuery);
      Promise.all([
        fetchCompany.then(res => {
          company.value = res.data;
        }),
        fetchInvoice.then(res => {
          invoice.value = res.data;
        })
      ])
          .then(() => loader.value = false)
          .catch((err) => {
            showError("Something went wrong");
            loader.value = false;
          })

    });
    return {
      company,
      loader,
      invoice,
      tableItems,
      html2Pdf,
      htmlToPdfOptions,
      formatCurrency,
      currencyToWord,
      exportToPDF,
      bill_number
    }
  }
}
</script>
